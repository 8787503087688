/******************/
/* THEME SELECTOR */
/******************/
.btn-theme-choice {
    width: 20vmin;
    height: 20vmin;
}

@media (min-width: 576px) {
    .btn-theme-choice {
        width: 15vmin;
        height: 15vmin;
    }
}

@media (min-width: 992px) {
    .btn-theme-choice {
        width: 10vmin;
        height: 10vmin;
    }
}




/***********/
/* BORDERS */
/***********/
.border {
    border-color: var(--bs-body-text) !important;
}

.border-primary {
    border-color: var(--bs-primary) !important;
}

.border-secondary {
    border-color: var(--bs-secondary) !important;
}

.border-success {
    border-color: var(--bs-success) !important;
}

.border-danger {
    border-color: var(--bs-danger) !important;
}

.border-warning {
    border-color: var(--bs-warning) !important;
}

.border-info {
    border-color: var(--bs-info) !important;
}

.border-light {
    border-color: var(--bs-light) !important;
}

.border-dark {
    border-color: var(--bs-dark) !important;
}

/********/
/* TEXT */
/********/
.text-primary {
    color: var(--bs-primary) !important;
}

.text-secondary {
    color: var(--bs-secondary) !important;
}

.text-success {
    color: var(--bs-success) !important;
}

.text-danger {
    color: var(--bs-danger) !important;
}

.text-warning {
    color: var(--bs-warning) !important;
}

.text-info {
    color: var(--bs-info) !important;
}

.text-light {
    color: var(--bs-light) !important;
}

.text-dark {
    color: var(--bs-dark) !important;
}

/***********/
/* BUTTONS */
/***********/
.btn-primary {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary-border) !important;
    color: var(--bs-primary-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}


.btn-secondary {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary-border) !important;
    color: var(--bs-secondary-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}


.btn-success {
    background-color: var(--bs-success) !important;
    border-color: var(--bs-success-border) !important;
    color: var(--bs-success-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-danger {
    background-color: var(--bs-danger) !important;
    border-color: var(--bs-danger-border) !important;
    color: var(--bs-danger-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-warning {
    background-color: var(--bs-warning) !important;
    border-color: var(--bs-warning-border) !important;
    color: var(--bs-warning-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-info {
    background-color: var(--bs-info) !important;
    border-color: var(--bs-info-border) !important;
    color: var(--bs-info-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-light {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light-border) !important;
    color: var(--bs-light-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.btn-dark {
    background-color: var(--bs-dark) !important;
    border-color: var(--bs-dark-border) !important;
    color: var(--bs-dark-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

/****************/
/* THEME COLORS */
/****************/
.theme-body {
	background-color: var(--bs-body) !important;
    border-color: var(--bs-body-text) !important;
	color: var(--bs-body-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-primary {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary-border) !important;
    color: var(--bs-primary-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-secondary {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary-border) !important;
    color: var(--bs-secondary-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-success {
    background-color: var(--bs-success) !important;
	border-color: var(--bs-success-border);
    color: var(--bs-success-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-danger {
    background-color: var(--bs-danger) !important;
	border-color: var(--bs-danger-border);
    color: var(--bs-danger-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-warning {
    background-color: var(--bs-warning) !important;
	border-color: var(--bs-warning-border) !important;
    color: var(--bs-warning-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-info {
    background-color: var(--bs-info) !important;
	border-color: var(--bs-info-border);
    color: var(--bs-info-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-light {
    background-color: var(--bs-light) !important;
	border-color: var(--bs-light-border) !important;
    color: var(--bs-light-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}

.theme-dark {
    background-color: var(--bs-dark) !important;
	border-color: var(--bs-dark-border) !important;
    color: var(--bs-dark-text) !important;
    transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}