img {
    width: 100%;
    border-radius: 10px;
}

.content {
    max-width: 600px;
}

.logo {
max-height: 250px;
}

#results {
    border-style: solid;
    border-width: 3pt;
    border-radius: 10px;
}

#results p {
    font-size: 24pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.funFactsTitle {
    font-size: 24pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.funFacts {
    font-size: 18pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.hint {
    font-size: 18pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#bottom_spacer {
    height: 15vmax;  
}

#themebtn {
    position: absolute;
    top: 0px;
    right: 0px;
}

#avocado {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 25vmin;
}

@media (min-width: 576px){
    #bottom_spacer {
        height: 15vmin;
    }

    #avocado {
        width: 15vmin;
    }
}

@media (min-width: 992px) {
    #bottom_spacer {
        height: 10vmin;
    }

    #avocado {
        width: 10vmin;
    }

    #avocado:hover {
        transform: rotate(20deg);
    }   
 }